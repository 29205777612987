import * as actionTypes from './actionTypes';
import axios from '../../axios-releasemanager';
import settings from '../../settings';

export const loadReleasesStart = () => {
  return {
    type: actionTypes.RM_LOAD_RELEASES_START,
  };
};

export const loadReleasesSuccess = (releaseData) => {
  return {
    type: actionTypes.RM_LOAD_RELEASES_SUCCESS,
    releases: releaseData,
  };
};

export const loadReleasesFail = (error) => {
  return {
    type: actionTypes.RM_LOAD_RELEASES_FAIL,
    error: error,
  };
};

export const loadReleases = (projectKey) => {
  return async (dispatch) => {
    dispatch(loadReleasesStart());
    const baseUrl = settings.baseServiceUrl;
    let buildsRes = null;
    let buildsError = null;
    let releasesRes = null;
    let releasesError = null;
    let stablesRes = null;
    let stablesError = null;
    try {
      const buildsUrl = baseUrl + `/builds/projects/${projectKey}/revisions`;
      buildsRes = await axios.get(buildsUrl);
    } catch (error) {
      buildsError = 'Unable to get Build versions: ' + error.response.status;
      buildsRes = { data: [{ revision: '', lastModified: '' }] };
    }
    try {
      const releasesUrl =
        baseUrl + `/releases/projects/${projectKey}/revisions`;
      releasesRes = await axios.get(releasesUrl);
    } catch (error) {
      releasesError =
        'Unable to get Release versions: ' + error.response.status;
      releasesRes = { data: [{ revision: '', lastModified: '' }] };
    }
    try {
      const stablesUrl = baseUrl + `/stable/projects/${projectKey}/revisions`;
      stablesRes = await axios.get(stablesUrl);
    } catch (error) {
      stablesError = 'Unable to get Stable versions: ' + error.response.status;
      stablesRes = { data: [{ revision: '', lastModified: '' }] };
    }
    if (buildsError && releasesError && stablesError) {
      const errorMessages = [];
      if (buildsError) errorMessages.push(buildsError);
      if (releasesError) errorMessages.push(releasesError);
      if (stablesError) errorMessages.push(stablesError);
      const errorMessage = errorMessages.join(' | ');
      dispatch(loadReleasesFail(errorMessage));
    } else {
      let currentRelease = null;
      try {
        const currentReleaseUrl = settings.distsUrl + `/${projectKey}/latest.txt`;
        const currentReleaseRes = await axios.get(currentReleaseUrl);
        if (currentReleaseRes && currentReleaseRes.data)
          currentRelease = currentReleaseRes.data;
      } catch {}
      let currentStable = null;
      try {
        const currentStableUrl = settings.stabledistsUrl + `/${projectKey}/latest.txt`;
        const currentStableRes = await axios.get(currentStableUrl);
        if (currentStableRes && currentStableRes.data)
          currentStable = currentStableRes.data;
      } catch {}
      const yearAgoRx = new Date(new Date() - 365 * 24 * 60 * 60 * 1000);
      const latestRx = /^latest/;
      const uniqueReleases = [
        ...new Set([
          ...buildsRes.data.map(
            (i, index) =>
              !i.revision.match(latestRx) &&
              (index < 10 || new Date(i.lastModified) > yearAgoRx) &&
              i.revision
          ),
          ...releasesRes.data.map(
            (i, index) =>
              !i.revision.match(latestRx) &&
              (index < 10 || new Date(i.lastModified) > yearAgoRx) &&
              i.revision
          ),
          ...stablesRes.data.map(
            (i, index) =>
              !i.revision.match(latestRx) &&
              (index < 10 || new Date(i.lastModified) > yearAgoRx) &&
              i.revision
          ),
        ]),
      ].filter((r) => r && r.length > 0);
      let data = [
        ...uniqueReleases
          .sort()
          .reverse()
          .map((relName) => {
            //if (!relName) return;
            const bld = buildsRes.data.find((i) => i.revision === relName);
            const rel = releasesRes.data.find((i) => i.revision === relName);
            const stb = stablesRes.data.find((i) => i.revision === relName);
            let lastModified = null;
            let hasBuild = false;
            let hasRelease = false;
            let hasStable = false;
            let isRelease = false;
            let isStable = false;
            if (bld) {
              hasBuild = true;
              lastModified = bld.lastModified;
            }
            if (rel) {
              hasRelease = true;
              if (!lastModified || rel.lastModified > lastModified)
                lastModified = rel.lastModified;
              if (currentRelease && relName === currentRelease)
                isRelease = true;
            }
            if (stb) {
              hasStable = true;
              if (!lastModified || stb.lastModified > lastModified)
                lastModified = stb.lastModified;
              if (currentStable && relName === currentStable) isStable = true;
            }
            return {
              version: relName,
              lastModified: lastModified,
              hasBuild: hasBuild,
              hasRelease: hasRelease,
              isRelease: isRelease,
              hasStable: hasStable,
              isStable: isStable,
            };
          }),
      ];
      dispatch(loadReleasesSuccess(data));
    }
  };
};

export const releaseVersionStart = () => {
  return {
    type: actionTypes.RM_RELEASE_VERSION_START,
  };
};

export const releaseVersionSuccess = () => {
  return {
    type: actionTypes.RM_RELEASE_VERSION_SUCCESS,
  };
};

export const releaseVersionFail = (error) => {
  return {
    type: actionTypes.RM_RELEASE_VERSION_FAIL,
    error: error,
  };
};

export const releaseVersion = (proj, release) => {
  return async (dispatch) => {
    dispatch(releaseVersionStart());
    const url = settings.baseServiceUrl +
      `/builds/projects/${proj}/unreleasedRevisions/${release}/release`;
    try {
      await axios.post(url);
      dispatch(releaseVersionSuccess());
      dispatch(loadReleases(proj));
    } catch (error) {
      dispatch(releaseVersionFail(error.response.data.error));
    }
  };
};

export const stabilizeVersionStart = () => {
  return {
    type: actionTypes.RM_STABILIZE_VERSION_START,
  };
};

export const stabilizeVersionSuccess = () => {
  return {
    type: actionTypes.RM_STABILIZE_VERSION_SUCCESS,
  };
};

export const stabilizeVersionFail = (error) => {
  return {
    type: actionTypes.RM_STABILIZE_VERSION_FAIL,
    error: error,
  };
};

export const stabilizeVersion = (proj, release) => {
  return async (dispatch) => {
    dispatch(stabilizeVersionStart());
    const url = settings.baseServiceUrl +
      `/releases/projects/${proj}/unstableVersions/${release}/stabilize`;
    try {
      await axios.post(url);
      dispatch(stabilizeVersionSuccess());
      dispatch(loadReleases(proj));
    } catch (error) {
      dispatch(stabilizeVersionFail(error.response.data.error));
    }
  };
};

export const rollbackReleaseVersionStart = () => {
  return {
    type: actionTypes.RM_ROLLBACK_RELEASE_START,
  };
};

export const rollbackReleaseVersionSuccess = () => {
  return {
    type: actionTypes.RM_ROLLBACK_RELEASE_SUCCESS,
  };
};

export const rollbackReleaseVersionFail = (error) => {
  return {
    type: actionTypes.RM_ROLLBACK_RELEASE_FAIL,
    error: error,
  };
};

export const rollbackReleaseVersion = (proj, release) => {
  return async (dispatch) => {
    dispatch(rollbackReleaseVersionStart());
    const url = settings.baseServiceUrl +
      `/releases/projects/${proj}/rollBack/${release}`;
    try {
      await axios.post(url);
      dispatch(rollbackReleaseVersionSuccess());
      dispatch(loadReleases(proj));
    } catch (error) {
      dispatch(rollbackReleaseVersionFail(error.response.data.error));
    }
  };
};

export const rollbackStableVersionStart = () => {
  return {
    type: actionTypes.RM_ROLLBACK_STABLE_START,
  };
};

export const rollbackStableVersionSuccess = () => {
  return {
    type: actionTypes.RM_ROLLBACK_STABLE_SUCCESS,
  };
};

export const rollbackStableVersionFail = (error) => {
  return {
    type: actionTypes.RM_ROLLBACK_STABLE_FAIL,
    error: error,
  };
};

export const rollbackStableVersion = (proj, release) => {
  return async (dispatch) => {
    dispatch(rollbackStableVersionStart());
    const url = settings.baseServiceUrl +
      `/stable/projects/${proj}/rollBack/${release}`;
    try {
      await axios.post(url);
      dispatch(rollbackStableVersionSuccess());
      dispatch(loadReleases(proj));
    } catch (error) {
      dispatch(rollbackStableVersionFail(error.response.data.error));
    }
  };
};
