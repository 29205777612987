import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as actions from '../store/actions';
import Releases from './Releases';
import Layout from './Layout';

const ReleaseManager = (props) => {
  const { onLoadProjects } = props;

  useEffect(() => {
    onLoadProjects();
  }, [onLoadProjects]);

  return (
    <Layout>
      <Releases
        releaseList={props.releases}
        selectedProject={props.selectedProject}
        isLoadingReleases={props.loadingReleases}
        isLoadingActions={props.loadingActions}
        isLoadingProjects={props.loadingProjects}
        isAuthenticated={props.isAuthenticated}
        handleReleaseVersion={props.onReleaseVersion}
        handleStabilizeVersion={props.onStabilizeVersion}
        handleRollbackReleaseVersion={props.onRollbackReleaseVersion}
        handleRollbackStableVersion={props.onRollbackStableVersion}
        />
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    projects: state.projects.projects,
    selectedProject: state.projects.selectedProject,
    loadingProjects: state.projects.loadingProjects,
    releases: state.releases.releases,
    loadingReleases: state.releases.loadingReleases,
    loadingActions: state.releases.loadingActions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadProjects: () => dispatch(actions.loadProjects()),
    onLoadReleases: (proj) => dispatch(actions.loadReleases(proj)),
    onSelectProject: (proj) => dispatch(actions.setSelectedProject(proj)),
    onReleaseVersion: (proj, version) =>
      dispatch(actions.releaseVersion(proj, version)),
    onStabilizeVersion: (proj, version) =>
      dispatch(actions.stabilizeVersion(proj, version)),
    onRollbackReleaseVersion: (proj, version) =>
      dispatch(actions.rollbackReleaseVersion(proj, version)),
    onRollbackStableVersion: (proj, version) =>
      dispatch(actions.rollbackStableVersion(proj, version)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseManager);
