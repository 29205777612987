import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const Logout = () => {
  const history = useHistory();

  const handleLogout = () => {
    history.push('/logout');
  };

  return (
    <Button color="inherit" onClick={handleLogout} style={{ minWidth: '7em' }}>
      Log Out
    </Button>
  );
};

export default Logout;
