import * as actionTypes from '../actions/actionTypes';

const initialState = {
  releases: [],
  loadingReleases: false,
  loadingActions: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RM_LOAD_RELEASES_START:
      return { ...state, loadingReleases: true, releases: [], error: null };
    case actionTypes.RM_LOAD_RELEASES_SUCCESS:
      return {
        ...state,
        releases: [...action.releases],
        loadingReleases: false,
        error: null,
      };
    case actionTypes.RM_LOAD_RELEASES_FAIL:
      return {
        ...state,
        loadingReleases: false,
        releases: [],
        error: action.error,
      };
    case actionTypes.RM_RELEASE_VERSION_START:
    case actionTypes.RM_STABILIZE_VERSION_START:
    case actionTypes.RM_ROLLBACK_RELEASE_START:
    case actionTypes.RM_ROLLBACK_STABLE_START:
      return { ...state, loadingActions: true, error: null };
    case actionTypes.RM_RELEASE_VERSION_SUCCESS:
    case actionTypes.RM_STABILIZE_VERSION_SUCCESS:
    case actionTypes.RM_ROLLBACK_RELEASE_SUCCESS:
    case actionTypes.RM_ROLLBACK_STABLE_SUCCESS:
      return { ...state, loadingActions: false, error: null };
    case actionTypes.RM_RELEASE_VERSION_FAIL:
    case actionTypes.RM_STABILIZE_VERSION_FAIL:
    case actionTypes.RM_ROLLBACK_RELEASE_FAIL:
    case actionTypes.RM_ROLLBACK_STABLE_FAIL:
      return { ...state, loadingActions: false, error: action.error };
    default:
      return state;
  }
};

export default reducer;
