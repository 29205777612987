import * as actionTypes from './actionTypes';
import moment from 'moment';

export const saveToken = (token, username, expires) => {
  localStorage.setItem('token', token);
  localStorage.setItem('username', username);
  localStorage.setItem('tokenexpires', expires);
  return {
    type: actionTypes.AUTH_SAVE_TOKEN,
    token: token,
  };
};

export const logout = () => {
  localStorage.clear();
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    const tokenexpires = localStorage.getItem('tokenexpires');
    if (!token) {
      dispatch(logout());
    } else if (moment(tokenexpires).isBefore(moment().utc())) {
      console.log('this should be a logout. the token is expired');
      dispatch(logout());
    } else {
      dispatch(saveToken(token, username, tokenexpires));
    }
  };
};
