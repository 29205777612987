import * as actionTypes from '../actions/actionTypes';

const initialState = {
  projects: [],
  selectedProject: null,
  loadingProjects: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RM_LOAD_PROJECTS_START:
      return { ...state, loadingProjects: true, projects: [], error: null };
    case actionTypes.RM_LOAD_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: [...action.projects],
        loadingProjects: false,
        error: null,
      };
    case actionTypes.RM_LOAD_PROJECTS_FAIL:
      return {
        ...state,
        projects: [],
        loadingProjects: false,
        error: action.error,
      };
    case actionTypes.RM_SET_SELECTED_PROJECT:
      return { ...state, selectedProject: action.projectName };
    default:
      return state;
  }
};

export default reducer;

/*

      const np = [];
      for (let i = 0; i < action.nestedProjects.length; i++){
        const p = action.nestedProjects[i];
        if (typeof p !== 'object') {
          np.push({ name: 'err: must be objects' });
          continue;
        };
        if (!p.name) {
          np.push({ name: 'err: no name' });
          continue;
        }
        if (!p.subProjects) {
          // this is fine
          np.push({ name: `${p.name}` });
          continue;
        }
        if (!Array.isArray(p.subProjects)) {
          np.push({
            name: `${p.name}`,
            subProjects: ['err: sub projects not an array']
          });
          continue;
        }
        let twoLevelsOnly = true;
        const subProjects = [...p.subProjects];
        for (let j = 0; j < subProjects.length; j++){
          if (typeof subProjects[j] === 'object') {
            // subProjects[j] = 'err: menu is too deep';
            twoLevelsOnly = false;
            break;
          }
        }
        if (!twoLevelsOnly) {
          np.push({
            name: `${p.name}`,
            subProjects: ['err: menu is too deep']
          });
          continue;
        }
        np.push({
          name: `${p.name}`,
          subProjects: subProjects
        })
      }
*/