import React from 'react';
import rocketLogo from '../../assets/images/rocket512.png';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  LogoDiv: {
    padding: 4,
    height: theme.mixins.toolbar.minHeight,
    width: theme.mixins.toolbar.minHeight,
    [theme.breakpoints.up('xs')]: {
      height: 48,
      marginRight: 16,
    },
    [theme.breakpoints.up('sm')]: {
      height: theme.mixins.toolbar.minHeight,
    },
  },
  Rocket: {
    maxHeight: '100%',
    height: 'auto',
  },
}));

const Logo = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.LogoDiv}>
      <img src={rocketLogo} alt="Rocket Logo" className={classes.Rocket} />
    </div>
  );
};

export default Logo;
