import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  footerContent: {
    margin: 4,
    color: "#ccc",
  },
  footer: {
    backgroundColor: "#333",
  },
}));

function Copyright() {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <Typography
        className={classes.footerContent}
        variant="body2"
        color="textSecondary"
        align="center"
      >
        {"Copyright © "}
        <Link target="blank" color="inherit" href="https://oboesax.com/">
          James Perreault
        </Link>
        {` ${new Date().getFullYear()}.`}
      </Typography>
    </div>
  );
}

export default Copyright;
