import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';

import EmojiPeople from '@material-ui/icons/EmojiPeople';
import DirectionsWalk from '@material-ui/icons/DirectionsWalk';
import DirectionsRun from '@material-ui/icons/DirectionsRun';
import DirectionsBike from '@material-ui/icons/DirectionsBike';
import NewReleases from '@material-ui/icons/NewReleases';
import MoreHoriz from '@material-ui/icons/MoreHoriz';

import releaseActions from './releaseActions';

const useStyles = makeStyles({
  table: { minWidth: 550 },
  paper: { maxWidth: 900, margin: '0 auto' },
});

const ReleaseTable = (props) => {
  const classes = useStyles();
  const sortedReleases = [...props.releases].sort((a, b) =>
    a.lastModified > b.lastModified ? -1 : 1
  );

  return (
    <TableContainer component={Paper} className={classes.paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Version</TableCell>
            <TableCell align="center">Build</TableCell>
            <TableCell align="center">Release</TableCell>
            <TableCell align="center">Stable</TableCell>
            <TableCell align="center">Rollback</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedReleases.map((release) => (
            <TableRow key={release.version}>
              <TableCell component="th" scope="row">
                {release.version}
              </TableCell>
              <TableCell align="center">
                {release.hasBuild ? <EmojiPeople /> : null}
              </TableCell>
              <TableCell align="center">
                {release.hasRelease ? (
                  <DirectionsRun />
                ) : (
                  <IconButton
                    onClick={() =>
                      props.confirmAction(
                        release.version,
                        releaseActions.release
                      )
                    }
                  >
                    <DirectionsWalk style={{ opacity: '0.3' }} />
                  </IconButton>
                )}
                {release.isRelease ? (
                  <NewReleases
                    style={{ marginLeft: '.2em' }}
                    titleAccess="Current Latest Release"
                  />
                ) : null}
              </TableCell>
              <TableCell align="center">
                {release.hasStable ? (
                  <DirectionsBike />
                ) : release.hasRelease ? (
                  <IconButton
                    onClick={() =>
                      props.confirmAction(
                        release.version,
                        releaseActions.stabilize
                      )
                    }
                  >
                    <DirectionsWalk style={{ opacity: '0.3' }} />
                  </IconButton>
                ) : null}
                {release.isStable ? (
                  <NewReleases
                    style={{ marginLeft: '.3em' }}
                    titleAccess="Current Latest Stable"
                  />
                ) : null}
              </TableCell>
              <TableCell align="center">
                {(release.hasRelease && !release.isRelease) ||
                (release.hasStable && !release.isStable) ? (
                  <IconButton
                    aria-controls="rollback-menu"
                    aria-haspopup="true"
                    onClick={(e) =>
                      props.showRollbackMenu(
                        e,
                        release.version,
                        release.hasRelease && !release.isRelease,
                        release.hasStable && !release.isStable
                      )
                    }
                  >
                    <MoreHoriz />
                  </IconButton>
                ) : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ReleaseTable.propTypes = {
  releases: PropTypes.array,
  confirmAction: PropTypes.func,
  showRollbackMenu: PropTypes.func,
};

export default ReleaseTable;
