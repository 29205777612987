import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import MaterialToolbar from '../components/Toolbar';
import MaterialSideDrawer from './SideDrawer';
import ErrorDisplay from '../components/ErrorDisplay';

const useStyles = makeStyles((theme) => ({
  Content: {
    margin: '2rem',
    [theme.breakpoints.down('sm')]: {
      margin: '1rem',
    },
  },
}));

const Layout = (props) => {
  const classes = useStyles();

  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const sideDrawerClosedHandler = () => {
    setSideDrawerOpen(false);
  };
  const sideDrawerOpenHandler = () => {
    setSideDrawerOpen(true);
  };

  return (
    <React.Fragment>
      <MaterialToolbar
        openSideDrawer={sideDrawerOpenHandler}
        isAuthenticated={props.isAuthenticated}
      />
      <ErrorDisplay error={props.projectErrorMessage} />
      <ErrorDisplay error={props.releaseErrorMessage} />
      <MaterialSideDrawer
        sideDrawerOpen={sideDrawerOpen}
        setSideDrawerClosed={sideDrawerClosedHandler}
        setSideDrawerOpen={sideDrawerOpenHandler}
      />
      <main className={classes.Content}>{props.children}</main>
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    releaseErrorMessage: state.releases.error,
    projectErrorMessage: state.projects.error,
  };
};

export default connect(mapStateToProps)(Layout);
