import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import LogoutButton from './LogoutButton';
import TabItems from '../containers/TabItems';
import Logo from './Logo';

const MaterialToolbar = (props) => {
  const useStyles = makeStyles((theme) => ({
    flex: {
      flex: 1,
    },
  }));

  const classes = useStyles();

  let things = (
    <React.Fragment>
      <div style={{ flex: 1 }} />
    </React.Fragment>
  );
  if (props.isAuthenticated) {
    things = (
      <React.Fragment>
        <Hidden smUp>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={props.openSideDrawer}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden xsDown>
          <TabItems />
        </Hidden>
        <div className={classes.flex} />
        <div style={{ height: '100%' }}>
          <LogoutButton />
        </div>
      </React.Fragment>
    );
  }

  return (
    <AppBar position="static" color="default">
      <Toolbar>
        <Logo />
        {things}
      </Toolbar>
    </AppBar>
  );
};

MaterialToolbar.propTypes = {
  isAuthenticated: PropTypes.bool,
  openSideDrawer: PropTypes.func,
};

export default MaterialToolbar;
