import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  errorPaper: {
    width: '100%',
    borderRadius: 0,
    padding: '2rem',
  },
}));

const ErrorDisplay = (props) => {
  const classes = useStyles();
  if (props.error) console.log(props.error);

  let errorDisplay = null;
  if (props.error) {
    errorDisplay = (
      <Box component={Paper} className={classes.errorPaper}>
        <Typography variant="h5" color="error">
          {props.error}
        </Typography>
      </Box>
    );
  }
  return errorDisplay;
};

export default ErrorDisplay;
