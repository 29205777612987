import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter, useHistory, Switch } from 'react-router-dom';
import classes from './App.module.css';
import Login from "./components/Login";
import Code from "./components/Code";
import ReleaseManager from './containers/ReleaseManager';
import Projects from './advanced/containers/Projects';
import Settings from './advanced/containers/Settings';
import * as actions from './store/actions/index';

function App(props) {
  const { onAuthCheckState } = props;
  useEffect(() => {
    onAuthCheckState();
  }, [onAuthCheckState]);

  const token = localStorage.getItem("token");
  // const username = localStorage.getItem("username");
  // const tokenexpires = localStorage.getItem("tokenexpires");
  const isAuthenticated = !!token;

  const history = useHistory();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("tokenexpires");
    history.push("/login");
  };

  let page = (
    <Switch>
      <Route path="/code" exact component={Code} />
      <Route path="/login" exact component={Login} />
      <Redirect to="/login" />
    </Switch>
  );

  if (isAuthenticated) {
    page = (
      <div className={classes.App}>
        <Switch>
          <Route path="/logout" exact
            render={() => {
              handleLogout();
            }}
          />
          <Route path="/projects" component={Projects} />
          <Route path="/settings" component={Settings} />
          <Route path="/" exact component={ReleaseManager} />
          <Redirect to="/" />
        </Switch>
      </div>
    );
  }

  return page;
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuthCheckState: () => dispatch(actions.authCheckState()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
