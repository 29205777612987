import React, { useState } from 'react';
import PropTypes from 'prop-types';

import LinearProgress from '@material-ui/core/LinearProgress';

import ErrorDisplay from '../components/ErrorDisplay';
import releaseActions from '../components/releaseActions';
import ReleaseTable from '../components/ReleaseTable';
import ReleaseDialog from '../components/ReleaseDiaglog';
import ReleaseMenu from '../components/ReleaseMenu';

const Releases = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedHasRelease, setSelectedHasRelease] = useState(null);
  const [selectedHasStable, setSelectedHasStable] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const prod = true;

  const showRollbackMenu = (event, version, hasRelease, hasStable) => {
    setSelectedVersion(version);
    setSelectedHasRelease(hasRelease);
    setSelectedHasStable(hasStable);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseRollbackMenu = () => {
    setAnchorEl(null);
  };

  const confirmAction = (version, action) => {
    setSelectedVersion(version);
    setSelectedAction(action);
    setConfirmationOpen(true);
  };

  const handleConfirmationConfirmed = () => {
    setConfirmationOpen(false);
    if (selectedVersion && selectedAction)
      handleAction(selectedVersion, selectedAction);
  };

  const handleConfirmationCancel = () => {
    setConfirmationOpen(false);
    setSelectedVersion(null);
    setSelectedAction(null);
  };

  const handleAction = (version, action) => {
    if (!prod)
      console.log(
        'not production. otherwise, we would have:',
        action,
        props.selectedProject,
        version
      );
    else {
      switch (action) {
        case releaseActions.release:
          props.handleReleaseVersion(props.selectedProject, version);
          break;
        case releaseActions.stabilize:
          props.handleStabilizeVersion(props.selectedProject, version);
          break;
        case releaseActions.rollbackRelease:
          props.handleRollbackReleaseVersion(props.selectedProject, version);
          break;
        case releaseActions.rollbackStable:
          props.handleRollbackStableVersion(props.selectedProject, version);
          break;
        default:
          alert('Unknown action: ' + action);
          break;
      }
    }
  };

  let releaseTable = null;
  if (props.selectedProject) {
    releaseTable = <ErrorDisplay error="No Releases Found" />;
    if (props.releaseList.length > 0) {
      const rels = props.releaseList
        .sort((a, b) => (a.lastModified > b.lastModified ? -1 : 1))
        .slice(0, Math.min(50, props.releaseList.length));
      releaseTable = (
        <ReleaseTable
          releases={rels}
          confirmAction={confirmAction}
          showRollbackMenu={showRollbackMenu}
        />
      );
    }
  }
  if (props.isLoadingReleases) {
    releaseTable = <LinearProgress />;
  }
  if (props.isLoadingActions) {
    releaseTable = <LinearProgress color="secondary" />;
  }
  if (props.isLoadingProjects) {
    releaseTable = <LinearProgress color="secondary" />;
  }

  if (!props.isAuthenticated) {
    return null;
  }

  return (
    <React.Fragment>
      {releaseTable}
      <ReleaseMenu
        anchorEl={anchorEl}
        handleCloseRollbackMenu={handleCloseRollbackMenu}
        selectedHasRelease={selectedHasRelease}
        selectedHasStable={selectedHasStable}
        selectedVersion={selectedVersion}
        confirmAction={confirmAction}
      />
      <ReleaseDialog
        confirmationOpen={confirmationOpen}
        handleConfirmationCancel={handleConfirmationCancel}
        handleConfirmationConfirmed={handleConfirmationConfirmed}
        selectedAction={selectedAction}
        selectedVersion={selectedVersion}
      />
    </React.Fragment>
  );
};

Releases.propTypes = {
  releaseList: PropTypes.array,
  selectedProject: PropTypes.string,
  isLoadingReleases: PropTypes.bool,
  isLoadingActions: PropTypes.bool,
  isLoadingProjects: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  handleReleaseVersion: PropTypes.func,
  handleStabilizeVersion: PropTypes.func,
  handleRollbackReleaseVersion: PropTypes.func,
  handleRollbackStableVersion: PropTypes.func,
};

export default Releases;
