
const baseClientUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://releasemanager.oboesax.com";
const baseServerUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3030"
    : "/api";
const baseServiceUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3030/proxy/release/v1p0"
    : "/proxy/release/v1p0";

const settings = {
  baseClientUrl: baseClientUrl,
  baseServerUrl: baseServerUrl,
  baseServiceUrl: baseServiceUrl,
  distsUrl: '/dists',
  stabledistsUrl: '/stabledists'
};

export default settings;