import * as actionTypes from './actionTypes';
import axios from '../../axios-releasemanager';
import { loadReleases } from './releases';
import settings from '../../settings';

export const loadProjectsStart = () => {
  return {
    type: actionTypes.RM_LOAD_PROJECTS_START,
  };
};

export const loadProjectsSuccess = (projectData) => {
  return {
    type: actionTypes.RM_LOAD_PROJECTS_SUCCESS,
    projects: projectData,
  };
};

export const loadProjectsFail = (error) => {
  return {
    type: actionTypes.RM_LOAD_PROJECTS_FAIL,
    error: error,
  };
};

export const loadProjects = () => {
  return async (dispatch) => {
    dispatch(loadProjectsStart());
    const baseUrl = settings.baseServiceUrl;
    let buildsRes = null;
    let buildsError = null;
    let releasesRes = null;
    let releasesError = null;
    let stablesRes = null;
    let stablesError = null;
    let authorized = true;
    try {
      const buildsUrl = baseUrl + '/builds/projects';
      buildsRes = await axios.get(buildsUrl);
    } catch (error) {
      console.log(error);
      if (error.response.status === 401)
        authorized = false;
      else
        buildsError = 'Unable to get Builds/Projects: ' + error.response.status;
      buildsRes = { data: [] };
    }
    if (authorized) {
      try {
        const releasesUrl = baseUrl + '/releases/projects';
        releasesRes = await axios.get(releasesUrl);
      } catch (error) {
        if (error.response.status === 401)
          authorized = false;
        else
          releasesError = 'Unable to get Releases/Projects: ' + error.response.status;
        releasesRes = { data: [] };
      }
    }
    if (authorized) {
      try {
        const stablesUrl = baseUrl + '/stable/projects';
        stablesRes = await axios.get(stablesUrl);
      } catch (error) {
        if (error.response.status === 401)
          authorized = false;
        else
          stablesError = 'Unable to get Stable/Projects: ' + error.response.status;
        stablesRes = { data: [] };
      }
    }
    if (buildsError && releasesError && stablesError) {
      const errorMessages = [];
      if (buildsError) errorMessages.push(buildsError);
      if (releasesError) errorMessages.push(releasesError);
      if (stablesError) errorMessages.push(stablesError);
      const errorMessage = errorMessages.join(' | ');
      dispatch(loadProjectsFail(errorMessage));
    } else if (!authorized) {
      dispatch(loadProjectsFail("Please login"));
    } else {
      const uniqueProjects = [
        ...new Set([
          ...buildsRes.data,
          ...releasesRes.data,
          ...stablesRes.data,
        ]),
      ];
      dispatch(loadProjectsSuccess([...uniqueProjects.sort()]));
      const selectedProj = localStorage.getItem('selectedProject');
      if (selectedProj) {
        dispatch(setSelectedProject(selectedProj));
      }
    }
  };
};

export const selectProject = (proj) => {
  localStorage.setItem('selectedProject', proj);
  return {
    type: actionTypes.RM_SET_SELECTED_PROJECT,
    projectName: proj,
  };
};

export const setSelectedProject = (proj) => {
  return async (dispatch) => {
    dispatch(selectProject(proj));
    dispatch(loadReleases(proj));
  };
};

/*
const FindNestedProjects = (projectList) => {
  console.log('starting point', projectList);
  const nestedProjects = [];
  const sortedProjects = [...projectList].sort();
  let lastTopProject = "";
  let lastSubProjects = [];
  for (let i = 0; i < sortedProjects.length; i++){
    const thisProject = sortedProjects[i];
    console.log('looking at:', thisProject);
    if (lastTopProject.length > 0 && thisProject.toLowerCase().startsWith(lastTopProject.toLowerCase())) {
      console.log('found a sub project', thisProject);
      lastSubProjects.push(thisProject);
    } else {
      if (lastTopProject.length > 0) {
        const next = {};
        next.name = `${lastTopProject}`;
        if (lastSubProjects.length > 0) {
          next.subProjects = [...lastSubProjects];
        }
        nestedProjects.push(next);
      }
      lastTopProject = thisProject;
      lastSubProjects = [];
    }
  }
  if (lastTopProject.length > 0) {
    const next = {};
    next.name = `${lastTopProject}`;
    if (lastSubProjects.length > 0) {
      next.subProjects = [`${lastTopProject}`, ...lastSubProjects];
    }
    nestedProjects.push(next);
  }
  console.log('from the find', nestedProjects);
  return nestedProjects;
};
*/