import axios from 'axios';

const instance = axios.create({
  //baseURL: firebaseSettings.baseURL,
});
let cancel;

instance.interceptors.request.use(
  (request) => {
    // console.log('intercept req', request);
    const token = localStorage.getItem('token');
    if (token) {
      // console.log('have a token', state.auth.token);
      request.headers = { Authorization: `Bearer ${token}` };
    } else {
      console.log('no token found');
      if (cancel) {
        cancel(); // cancel request
      }
      request.cancelToken = new axios.CancelToken(function executor(c) {
        cancel = c;
      });
      return request;
    }
    // console.log('modified request', request);
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// instance.interceptors.response.use(
//   (response) => {
//     console.log(response);
//     return response;
//   },
//   (error) => {
//     console.log('error!');
//     console.log(error);
//     return Promise.reject({ ...error });
//   }
// );

export default instance;
