import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const TabItems = (props) => {
  const history = useHistory();
  const handleClickProjects = () => {
    history.push("/projects");
  };
  const handleClickOld = () => {
    history.push("/");
  };
  const handleClickSettings = () => {
    history.push("/settings");
  };
  let currentTab = false;
    if (props.selectedProject && props.projects && !props.loadingProjects) {
      for (let i = 0; i < props.projects.length; i++) {
        if (props.projects[i] === props.selectedProject) currentTab = i;
      }
  };

  const a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  };

  return (
    <Tabs
      value={currentTab}
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable project tabs"
    >
      <Tab
        label="Projects"
        key="projects"
        onClick={handleClickProjects}
        {...a11yProps("projects")}
      />
      <Tab
        label="Settings"
        key="settings"
        onClick={handleClickSettings}
        {...a11yProps("settings")}
      />
      <Tab
        label="Back to the old UI"
        key="oldui"
        onClick={handleClickOld}
        {...a11yProps("oldui")}
      />
    </Tabs>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

export default connect(mapStateToProps)(TabItems);
