import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import settings from '../settings';
import * as actions from '../store/actions';

const Code = (props) => {
  const history = useHistory();
  const { location, onSaveToken } = props;

  useEffect(() => {
    const handleCode = async () => {
      const baseServerUrl = settings.baseServerUrl;
      let success = true;
      // what does this do? what is "location"?
      if (location && location.search) {
        const parms = queryString.parse(location.search);
        if (
          parms.code &&
          parms.response_type &&
          parms.response_type === "code"
        ) {
          const payload = { code: parms.code };
          try {
            const ret = await axios.post(`${baseServerUrl}/clscode`, payload);
            if (ret.status === 200) {
              console.log(ret.data);
              localStorage.setItem("token", ret.data.token);
              localStorage.setItem("username", ret.data.username);
              localStorage.setItem("tokenexpires", ret.data.tokenexpires);
              onSaveToken(ret.data.token, ret.data.username, ret.data.tokenexpires);
            } else {
              success = false;
              console.log("error: ", ret.status);
            }
          } catch (e) {
            success = false;
            console.log("http post error: ", e);
          }
        } else {
          success = false;
          console.log("that's not a code");
        }
      }
      if (success) history.push("/");
      else history.push(`/login?messagecode=1`);
    };

    handleCode();
  }, [history, location, onSaveToken]);

  return <React.Fragment>finishing login...</React.Fragment>;
};


const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveToken: (token, username, expires) => dispatch(actions.saveToken(token, username, expires))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Code);
