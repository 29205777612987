export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_SAVE_TOKEN = 'AUTH_SAVE_TOKEN';

export const RM_LOAD_PROJECTS_START = 'RM_LOAD_PROJECTS_START';
export const RM_LOAD_PROJECTS_SUCCESS = 'RM_LOAD_PROJECTS_SUCCESS';
export const RM_LOAD_PROJECTS_FAIL = 'RM_LOAD_PROJECTS_FAIL';
export const RM_SET_SELECTED_PROJECT = 'RM_SET_SELECTED_PROJECT';

export const RM_LOAD_RELEASES_START = 'RM_LOAD_RELEASES_START';
export const RM_LOAD_RELEASES_SUCCESS = 'RM_LOAD_RELEASES_SUCCESS';
export const RM_LOAD_RELEASES_FAIL = 'RM_LOAD_RELEASES_FAIL';
export const RM_RELEASE_VERSION_START = 'RM_RELEASE_VERSION_START';
export const RM_RELEASE_VERSION_SUCCESS = 'RM_RELEASE_VERSION_SUCCESS';
export const RM_RELEASE_VERSION_FAIL = 'RM_RELEASE_VERSION_FAIL';
export const RM_STABILIZE_VERSION_START = 'RM_STABILIZE_VERSION_START';
export const RM_STABILIZE_VERSION_SUCCESS = 'RM_STABILIZE_VERSION_SUCCESS';
export const RM_STABILIZE_VERSION_FAIL = 'RM_STABILIZE_VERSION_FAIL';
export const RM_ROLLBACK_RELEASE_START = 'RM_ROLLBACK_RELEASE_START';
export const RM_ROLLBACK_RELEASE_SUCCESS = 'RM_ROLLBACK_RELEASE_SUCCESS';
export const RM_ROLLBACK_RELEASE_FAIL = 'RM_ROLLBACK_RELEASE_FAIL';
export const RM_ROLLBACK_STABLE_START = 'RM_ROLLBACK_STABLE_START';
export const RM_ROLLBACK_STABLE_SUCCESS = 'RM_ROLLBACK_STABLE_SUCCESS';
export const RM_ROLLBACK_STABLE_FAIL = 'RM_ROLLBACK_STABLE_FAIL';
