import React from 'react';
import PropTypes from 'prop-types';

import { useTheme, createTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ThemeProvider from '@material-ui/styles/ThemeProvider';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ReleaseDialog = (props) => {
  const theme = useTheme();
  return (
    <Dialog
      open={props.confirmationOpen}
      onClose={props.handleConfirmationCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Confirm Action...'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`Are you sure you want to ${props.selectedAction} version [${props.selectedVersion}]?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ThemeProvider
          theme={createTheme({
            palette: { primary: { ...theme.palette.error } },
          })}
        >
          <Button
            onClick={props.handleConfirmationCancel}
            color="primary"
            autoFocus
          >
            Cancel
          </Button>
        </ThemeProvider>
        <Button onClick={props.handleConfirmationConfirmed} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ReleaseDialog.propTypes = {
  confirmationOpen: PropTypes.bool,
  handleConfirmationCancel: PropTypes.func,
  handleConfirmationConfirmed: PropTypes.func,
  selectedAction: PropTypes.string,
  selectedVersion: PropTypes.string,
};

export default ReleaseDialog;
