import React from 'react';
import { connect } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import * as actions from '../store/actions';

const TabItems = (props) => {
  let currentTab = false;
  if (props.selectedProject && props.projects && !props.loadingProjects) {
    for (let i = 0; i < props.projects.length; i++) {
      if (props.projects[i] === props.selectedProject) currentTab = i;
    }
  }

  const a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  };

  const handleChange = () => {};

  return (
    <Tabs
      value={currentTab}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable project tabs"
    >
      {props.projects.map((proj, index) => (
        <Tab
          label={proj}
          key={index}
          onClick={() => props.onSelectProject(proj)}
          {...a11yProps(index)}
        />
      ))}
    </Tabs>
  );
};

const mapStateToProps = (state) => {
  return {
    projects: state.projects.projects,
    selectedProject: state.projects.selectedProject,
    loadingProjects: state.projects.loadingProjects,
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSelectProject: (proj) => dispatch(actions.setSelectedProject(proj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabItems);
