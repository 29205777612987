import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import * as actions from '../store/actions';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
}));

const MaterialSideDrawer = (props) => {
  const classes = useStyles();

  const handleClick = (proj) => {
    props.setSideDrawerClosed();
    props.onSelectProject(proj);
  };

  return (
    <SwipeableDrawer
      anchor="left"
      open={props.sideDrawerOpen}
      onClose={props.setSideDrawerClosed}
      onOpen={props.setSideDrawerOpen}
    >
      <div className={classes.list} role="presentation">
        <List>
          {props.projects.map((proj) => (
            <ListItem button key={proj} onClick={() => handleClick(proj)}>
              <ListItemText primary={proj} />
            </ListItem>
          ))}
        </List>
      </div>
    </SwipeableDrawer>
  );
};

MaterialSideDrawer.propTypes = {
  projects: PropTypes.array,
  sideDrawerOpen: PropTypes.bool,
  setSideDrawerClosed: PropTypes.func,
  setSideDrawerOpen: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    projects: state.projects.projects,
    selectedProject: state.projects.selectedProject,
    loadingProjects: state.projects.loadingProjects,
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSelectProject: (proj) => dispatch(actions.setSelectedProject(proj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MaterialSideDrawer);
