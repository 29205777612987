import React from 'react';
import PropTypes from 'prop-types';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import releaseActions from '../components/releaseActions';

const ReleaseMenu = (props) => {
  return (
    <Menu
      id={'rollback-menu'}
      anchorEl={props.anchorEl}
      keepMounted
      open={Boolean(props.anchorEl)}
      onClose={props.handleCloseRollbackMenu}
    >
      <MenuItem
        disabled={!props.selectedHasRelease}
        onClick={() => {
          props.handleCloseRollbackMenu();
          props.confirmAction(
            props.selectedVersion,
            releaseActions.rollbackRelease
          );
        }}
      >
        Rollback to this Release
      </MenuItem>
      <MenuItem
        disabled={!props.selectedHasStable}
        onClick={() => {
          props.handleCloseRollbackMenu();
          props.confirmAction(
            props.selectedVersion,
            releaseActions.rollbackStable
          );
        }}
      >
        Rollback to this Stable
      </MenuItem>
    </Menu>
  );
};

ReleaseMenu.propTypes = {
  anchorEl: PropTypes.object,
  handleCloseRollbackMenu: PropTypes.func,
  selectedHasRelease: PropTypes.bool,
  selectedHasStable: PropTypes.bool,
  selectedVersion: PropTypes.string,
  confirmAction: PropTypes.func,
};

export default ReleaseMenu;
